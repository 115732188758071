import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form'
import setFieldData from 'final-form-set-field-data';
import clsx from 'clsx';

import userActions from '../../context/users/actions';
import transactionsAction from '../../context/transactions/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
// import { composeValidators, validateEmail, validateIsfilled, getObjectWithJsonDataToFromValues } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import SwitchboxInput from '../../components/forms/SwitchboxInput';
import UserProfileImage from '../../components/commons/UserProfileImage';
import LanguageSwitcher from '../../components/commons/LenguageSwitcher';
import { capitalizePhrase, capitalize } from '../../libraries/utils';
import StarRating from "react-svg-star-rating";
import IconPointsCircle from '../../components/customs/IconPointsCircle';
import IconCoinsCircle from '../../components/customs/IconCoinsCircle';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: true,
      settings: {
        notification: 0,
      },
      userTrx: [],
      creditBalance: {
        debit: 0,
        credit: 0,
        total: 0,
      },
    }
    this.mainMenu = [
      {
        icon: { left: 'user_info', right: 'arrow_right' }, label: { text: this.t('Personal information'), className: "text-gray-500 text-sm ml-2 font-normal" },
        action: { route: config.ROUTES.USER.INFO }
      },
      {
        icon: { left: 'notification' }, label: { text: this.t('Notifications'), className: "text-gray-500 text-sm ml-2 font-normal" },
        action: {
          field: {
            // component: <Field name="notification" component={SwitchboxInput} placeholder="notification" className="h-12 pt-3" />,
            component: SwitchboxInput,
            name: 'notification',
            placeholder: this.t('Notifications'),
            className: 'h-12 pt-3'
          }
        }
      },
      {
        icon: { left: 'language' }, label: { text: this.t('Language'), className: "text-gray-500 text-sm ml-2 font-normal" },
        action: {
          field: {
            component: LanguageSwitcher,
            name: 'language',
            placeholder: this.t('Language'),
            className: 'h-12 pt-3'
            // html: <span>{this.t("Spanish")}</span>
          }
        }
      },
      {
        icon: { left: 'terms', right: 'arrow_right' }, label: { text: capitalize(this.t('terms and conditions')), className: "text-gray-500 text-sm ml-2 font-normal" },
        action: { route: config.ROUTES.TERMS }
      },
      //{ icon: { left: 'helpme', right: 'arrow_right' }, label: { text: this.t('Help') },
      //  action: { route: config.ROUTES.HELP } },
      {
        icon: { left: 'user_info', right: 'arrow_right' }, label: { text: this.t('Change password'), className: "text-gray-500 text-sm ml-2 font-normal" },
        action: { route: config.ROUTES.USER.PASSWORD_CHANGE }
      },
      {
        icon: { left: 'logout' }, label: { text: this.t('Logout'), className: 'text-red-700 text-sm ml-2 font-normal' },
        action: { fn: () => this.props.onLogout() }
      },
    ];

  }

  async componentDidMount() {
    console.log('PROFILE PAGE', this.props.auth.user);
    await this.getUser();
    await this.getUserTrx()
    // this.getRewards();
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.settings === this.state.settings || 
  //     nextProps.user === this.props.user || 
  //     nextProps.transactions === this.props.transactions ||
  //     nextState.creditBalance === this.state.creditBalance) {
  //   return false;
  // }
  //   return true;
  // }

  getUser = async () => {
    const id = this.props.auth.user.id
    await this.props.onGetUser({ id });
    const { user } = this.props
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      const rate_total = user.item.json_data.rate_total
      const rate_count = user.item.json_data.rate_count
      const rating = this.props.location?.state?.rating ?? (rate_total && (rate_count !== 0) ? rate_total / rate_count : 0)
      this.setState({ settings: { ...this.props.user.item.accessibility }, rating });
    }
  }

  getUserTrx = async (currentPage) => {
    const baseParams = {
      page_num: currentPage,
      page_size: this.props.transactions?.query?.page_size || 8,
      type: "credits",
      order_by: "transactions__created_at",
      order_direction: "DESC",
    };

    const id = this.props.auth.user.id

    await this.props.onGetTransaction({ ...baseParams, where: { custom: { target: id, source: id, comparison: "OR", } } });
    if (this.props.transactions.error) {
      return notify(this.props.transactions.error.message);
    }
    const sourceTrx = this.props.transactions.items;

    const debit = sourceTrx
      .filter(trx => trx.source === id)
      .reduce((acc, trx) => acc + trx.total, 0)
    const credit = sourceTrx
      .filter(trx => trx.target === id)
      .reduce((acc, trx) => acc + trx.total, 0)

    this.setState({
      loading: false,
      creditBalance: {
        debit,
        credit,
        total: credit - debit,
      },
      userTrx: sourceTrx
    });

  }


  onSubmit = async (field, value) => {
    console.log('ONSUBMIT', field, value);
    let data = { user_type: config.USER_TYPE.REGULAR, id: this.props.auth.user.id, accessibility: {} };
    data.accessibility[field] = value;
    console.log('DATA', data);
    await this.props.onUpdate(data);
    const error = this.props.user.error;
    if (error) notify(this.t(error.message));
  }

  render() {
    const { rating, creditBalance, userTrx, loading } = this.state;
    const user = this.props.auth.user;
    return (
      <LayoutResponsiveWSmall
        main={{ className: "" }}
        header={{
          className: "bg-gradient-to-b fixed z-0 from-primary-focus to-transparent bg-transparent text-primary lg:hidden",
          // className: "bg-transparent text-primary-content absolute top-0 left-0 z-20",}
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.USER.PROFILE) }
        }}
        container={{ className: "px-0" }}
        loading={loading}
        showGoBackButton={true}
        onGoBackClick={()=> history.push(config.ROUTES.USER.PROFILE)}
        footer={{
          content: (
            <div className="flex items-center text-center pb-2 text-gray-400 text-xs bg-transparent">
              <span className="m-auto">{this.t("Version")} {config.VERSION}</span>
            </div>
          )
        }}
      >

        <ToastContainer />
        <section className="text-center relative h-64 bg-primary-focus lg:mt-5">
          <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-60'></div>
          <img src={user?.profile_image} alt='profile' className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' />
          <div className="p-4 relative z-10">
            <UserProfileImage data={user} picture={{ onClick: () => history.push(config.ROUTES.USER.INFO) }} />
            <div className="flex justify-center items-center gap-10 mx-auto">
              <div className="rounded-full text-white w-12 h-12 grid">
                <IconCoinsCircle className="sidebar--points" coins={user.rewards?.total.coins} />
              </div>
              <div>
                <h3 className='text-white'>{capitalizePhrase(user?.name)}</h3>
                <p className='text-center text-white'>Nivel {user.rewards?.level}</p>
              </div>
              <div className="rounded-full text-white w-12 h-12 grid">
                <IconPointsCircle className="sidebar--points" points={user.rewards?.total.points} iconClassName="w-5" />
              </div>
            </div>
            <div className='mx-auto mt-4 gap-2 items-center py-1 px-3 flex border border-white border-opacity-30 rounded-md w-min'>
              <p className='text-sm font-light text-white'>{(rating || 0).toFixed(1)}</p>  <StarRating containerClassName="flex" unit="float" initialRating={rating && rating} size={14} isReadOnly={true} />
            </div>

          </div>
        </section>
        <section className="px-4 pb-4 -mt-12 mb-12 lg:mb-0">
          <div className="w-full max-w-lg mx-auto">
            
            <div className='flex gap-2 justify-between'>
              <div className= 'stat card white z-20 text-success text-opacity-70 flex flex-col items-center py-3 px-3 max-w-[33%] w-full'>
                <span className='stat-value'>+{creditBalance.credit}</span>
                <span className='stat-title'>Créditos</span>
              </div>
              <div className='card white z-20 text-error text-opacity-70   flex flex-col items-center py-3 px-3 max-w-[33%] w-full'>
                <span className="font-bold text-4xl ">-{creditBalance.debit}</span>
                <span className='stat-title'>Débito</span>
              </div>
              <div className='card white z-20 text-slate-400 flex flex-col items-center py-3 px-3 max-w-[33%] w-full'>
                <span className='font-bold text-4xl '>{creditBalance.total >= 0 ? '+' : ''}{creditBalance.total}</span>
                <span className='stat-title'>Total</span>
              </div>
            </div>
            <div className="card white z-20 divide-y py-3 mt-4">
              <div className='px-4 mb-4'>
                <h3>Historial de Créditos</h3>
              </div>
              <div className='divide-y px-4'>
                {
                  userTrx.map((trx, index) => {
                    return (
                      <div key={index} className='flex justify-between py-4'>
                        <p>{trx.json_data.reason || "Sin razón"}</p> <span className={clsx(trx.source === user.id ? "text-error " : "text-success","font-medium", "")}>{trx.source === user.id ? "-" : "+"}{trx.total} credits</span>
                      </div>
                    )
                  })

                }
              </div>
            </div>
          </div>
        </section>
      </LayoutResponsiveWSmall>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    transactions: state.transactions.list,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onLogout: () => dispatch(userActions.logout()),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onGetBalance: (params) => dispatch(userActions.getBalance(params)),
    onGetTransaction: (params) => dispatch(transactionsAction.getAll(params)),


  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserSettings));
