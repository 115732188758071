import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import ImageUploadFileInput from '../../components/forms/ImageUploadFileInput';
import ImageUploadFileInputEdit from '../../components/forms/ImageUploadFileInputEdit';
import Button from '../../components/commons/Button';
import { Form, Field } from 'react-final-form';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Swal from 'sweetalert2';
import SwitchboxInput from '../../components/forms/SwitchboxInput';
import RadioInput from '../../components/forms/RadioInput';

import transactionsAction from '../../context/transactions/actions';
import productActions from '../../context/products/actions';
import filesActions from '../../context/files/actions';
import ProductElement from '../../components/customs/ProductElement';
import RatingIcons from '../../components/commons/RatingIcons';
import TextareaInput from '../../components/forms/TextareaInput';
import { getObjectWithJsonDataToFormValues, validateIsfilled } from '../../libraries/utils';
import internalsActions from '../../context/internals/actions';
import Loader from '../../components/commons/Loader';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class ConfirmReturned extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      imageUpdated: false,
      files: {},
      transaction:null,
      status:"",
      user: this.props.auth.user.id,
      user_rating:0,
      entregoATiempo: true,
      estaEnMismoEstado: true,
      uploadLoading: false,
    }
  }

  
  componentDidMount() {
    console.log('ME DEVOLVIERON EL PRODUCTO');
    const params = this.props.match.params;
    console.log('PARAMS', params);
   
    if(params.id){
      this.getTransaction(params);
    }else {
      history.push(config.ROUTES.OFFER);
    }
  }

  getTransaction = async (params)=> {
    await this.props.onGet(params);
    console.log('AFTER GET TRANSACTION');
    const { transaction } = this.props;
    console.log('TRANSACTION', transaction)
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      const transactionData = getObjectWithJsonDataToFormValues(
        transaction.item,
        ['id', 'owner', 'product', 'offer', 'status','borrower','type','sub_type','created_at']
      );
     this.setState({transaction:transactionData});
    }    
  }

  onSaveUserRating = (index) => {
    console.log('RATING USER', index);
    this.setState({ user_rating: index});
  }; 
  
  
  onSubmit= async (values) => {
    this.setState({uploadLoading: true})
    console.log("SUBMITTING")
    const { transaction, user_rating } = this.state
    const returned_image = this.state.files.identification_picture
    const {validateReturnedStatus, returnedAtTime,rateOwner}= values
    let data = {
      id:transaction.id,
      status: config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
      json_data: {...values, user_rating}
    }

    data.json_data.validateReturnStatus = (values.validateReturnStatusRadio === "si") ? true : false
    data.json_data.validateReturnTiming = (values.validateReturnTimingRadio === "si") ? true : false
    delete data.json_data.validateReturnTimingRadio
    delete data.json_data.validateReturnStatusRadio
    if(data.json_data.validateReturnTiming && data.json_data.validateReturnStatus) data.json_data.problemaProducto = null  
    if(data.json_data.problemaProducto){
      data.json_data.problemaProducto = data.json_data.problemaProducto.replaceAll('\n', ' ')
    }
    //Si tuviste algún problema podés enviarle un mensaje desde la sección de DEVOLUCIONES en el sidebar de la aplicación. 
    let line_5 = `Te puntuó con ${user_rating} Estrellas`
    //if(!(data.json_data.validateReturnTiming && data.json_data.validateReturnStatus))
    if(data.json_data.rateOwner) line_5 += ` y realizó el siguiente comentario sobre vos: ${data.json_data.rateOwner}`
    line_5 += `.`


    //console.log(data)
    //return
    console.log("DATA", data)
    await this.props.onChangeTransactionState(data);
    console.log('AFTER TRANSACTION STATUS CHANGING');
    const {transaction: updatedTransaction} = this.props
    if (updatedTransaction.error) { 
      notify(this.t(updatedTransaction.error.message));
      this.setState({uploadLoading: false})
    } else {
      let productType = (transaction.sub_type === "service") ? "servicio" : "favor" 
      let data2 = {
        id:transaction.product ? transaction.product.id : transaction.offer.id,
        status: config.STATUS.PRODUCTS.AVAILABLE
      }
      await this.props.onChangeProductState(data2);
      if (this.props.product.error) { 
        notify(this.t(this.props.product.error.message));
      } else {
        //let mailData = {
        //  template: "sharyco_generic",
        //  locale: "es",
        //  to:transaction.borrower.email,
        //  first_name: transaction.borrower.first_name,
        //  last_name:  transaction.borrower.last_name,
        //  params: {
        //      subject: transaction.product ? "Confirmación devolución" : `Te calificaron luego del ${productType} que recibiste`,
        //      line_1: `Hola ${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
        //      line_2: `${transaction.owner.first_name} ${transaction.owner.last_name}`,
        //      line_3: `${transaction.product ? " ha confirmado la devolución de " : ` ha confirmado que ha realizado el siguiente ${productType} `}`,
        //      line_4: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
        //      line_5,
        //      link_app: config.ROUTES.USER.ACTIVITIES,
        //      //has_button: false,
        //  }
        //}
        let mailData = {
          template: "returned_confirm",
          locale: "es",
          to:transaction.borrower.email,
          first_name: transaction.borrower.first_name,
          last_name:  transaction.borrower.last_name,
          params: {
              // subject: transaction.product ? "Confirmación devolución" : `Te calificaron luego del ${productType} que recibiste`,
              owner_name: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
              borrower_name:`${transaction.owner.first_name} ${transaction.owner.last_name}`,
              productType: `${transaction.product ? false : productType}`,
              product_name: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
              user_stars: `${user_rating}`,
              user_comment: rateOwner,
              //link_app: config.ROUTES.USER.ACTIVITIES,
              //has_button: false,
          }
        }
        console.log("MAILDATA", mailData)
        await this.props.onSendMail(mailData);
        const internals = this.props.internals;
        if (internals.error) {
          notify(this.t(internals.error.message));
        }
        history.push(config.ROUTES.USER.INVENTORY)
      }
    }
  }

  
  onSearch = (value) =>{
    console.log('searching', value);
  }

  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: {...this.state.files, [name]: null }});
  }


  onFileChangeImageInput = async ({file, data, name}) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    
    data.append("identifier", `confirmreturned_${ parseInt(Math.random()*1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({imageUpdated: true});
      this.setState({files: {...this.state.files, [name]:this.props.files.files.fileInfo.location}})
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }

  setEstaEnMismoEstado = (v) => {
    this.setState({estaEnMismoEstado: (v==='si' ? true : false)})
  }

  setEntregoATiempo = (v) => {
    this.setState({entregoATiempo: (v==='si' ? true : false)})    
  }

  //salio todo bien == ¿El ${getSubTypeString(transaction.sub_type)} fue recibido correctamente?
  render() {
      const {transaction,user_rating} = this.state
      const is_product_loan = transaction && transaction.product 
      const required = value => ((validateIsfilled(value) || (this.state.estaEnMismoEstado && this.state.entregoATiempo)) ? undefined : this.t("This field is required"))
      const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"

      return (
        <LayoutResponsiveWSmall
          main={{ className: ""}}
          header={{ 
            left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.USER.LOANS) },
            className: "bg-primary",
            title: transaction && transaction.product ? this.t("Me devolvieron el producto") : this.t(`Realicé el ${getSubTypeString(transaction?.sub_type)}`)
          }}
          container={{ className: "px-0"}}
          loading={this.state.uploadLoading}
          showGoBackButton={true}
          onGoBackClick={() => history.push(config.ROUTES.USER.LOANS)}
        >
          <ToastContainer/>
          { this.state.uploadLoading && (<Loader/>) }
          { transaction && !this.state.uploadLoading && (
          <section className="p-3">
            <ProductElement 
              product={transaction.product ? transaction.product : transaction.offer}
              owner={transaction.owner}
              points="120" 
              left={true}
            />
            <div className="pt-6">
            <Form onSubmit={(values) => this.onSubmit(values)} initialValues={
              {
                validateReturnTimingRadio: "si",
                validateReturnStatusRadio: "si",
              }
            }>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  {is_product_loan && <div className="w-full mb-3">
                    <Field 
                      name="identification_picture" 
                      component={ImageUploadFileInputEdit}
                      img={this.state.files.identification_picture} 
                      placeholder={this.t("Fotos del estado del producto al recibirlo")} 
                      label={this.t("Fotos del estado del producto al recibirlo")}
                      inputOnChange={this.onFileChangeImageInput}
                      clickButtonClear={this.onClearImageField}
                      inputClassName="input-bordered shadow-none"
                    />
                    {!this.state.files.identification_picture && <h5 className="text-center ">Adjuntar una foto para poder continuar </h5>}
                  </div>}
                  <div className="w-full mb-3">
                    <p>{is_product_loan ? this.t("¿Está en el mismo estado que dice la publicación?") : this.t(`¿Salió todo bien?`)}</p>
                    <div className="flex w-1/2">
                      <div className="w-1/2">
                       <Field type="radio" value="si" name="validateReturnStatusRadio" component={RadioInput} labelClassName="block" label={this.t("Si") } setExternal={this.setEstaEnMismoEstado}/>
                      </div>
                      <div className="w-1/2">
                        <Field type="radio" value="no" name="validateReturnStatusRadio" component={RadioInput} labelClassName="block" label={this.t("No") } setExternal={this.setEstaEnMismoEstado}/>
                      </div>
                    </div>
                  </div>
                  {is_product_loan && <div className="w-full mb-3">
                    <p>{is_product_loan ? this.t("¿Lo entregó a tiempo?") : this.t(`¿Realizó el ${getSubTypeString(transaction.sub_type)} a tiempo?`)}</p>
                    <div className="flex w-1/2">
                      <div className="w-1/2">
                        <Field type="radio" value="si" name="validateReturnTimingRadio" component={RadioInput} labelClassName="block" label={this.t("Si") } setExternal={this.setEntregoATiempo}/>
                      </div>
                      <div className="w-1/2">    
                        <Field type="radio" value="no" name="validateReturnTimingRadio" component={RadioInput} labelClassName="block" label={this.t("No") } setExternal={this.setEntregoATiempo}/>
                      </div>
                    </div>
                  </div>}
                  {/*(!this.state.estaEnMismoEstado || !this.state.entregoATiempo) &&*/}
                  {(!this.state.estaEnMismoEstado || !this.state.entregoATiempo) && <Field name="problemaProducto" component={TextareaInput} placeholder={this.t("Problema")} 
                  label={is_product_loan ? "¿Qué problema encontraste?" : "¿Qué problema encontraste?"} validate={required}/>}
                  <div className="w-full mb-3">
                    <h4>{this.t("Calificá al usuario")}</h4>
                    <h5>{this.t("¿Qué te pareció el trato y atención de " + transaction.borrower.first_name + "?")}</h5>
                    <RatingIcons
                      className="text-gray-300 my-3"
                      initalRating= {user_rating} 
                      onClick={this.onSaveUserRating}
                      disabled={false}
                      validate={required}
                    />
                    <Field name="rateOwner" component={TextareaInput} placeholder="Comentario" />
                  </div>             
                  <Button
                    className="btn-primary btn-block mt-10"
                    title={this.t("Confirmar")}
                    onClick={handleSubmit}
                    disabled={submitting || (!this.state.files.identification_picture && is_product_loan) || this.state.user_rating === 0}
                  />
                </form>
              )}
            </Form>
            </div>
          </section>)}
        </LayoutResponsiveWSmall>  
      )  
  
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transaction: state.transactions.current,
    files: state.files,
    product: state.products.current,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGet: (params) => dispatch(transactionsAction.get(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onChangeProductState: (params) => dispatch(productActions.saveOrUpdate(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConfirmReturned));

